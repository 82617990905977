<template>
  <div class="order_home">
    <div class="bx_item">
      <div class="fs-40">服务说明</div>
      <div class="box fs-28 rich-text">
        <div>四川省成都市青羊区太升南路鼓楼国际2906</div>
        <div>收件人：电信优享换机</div>
        <div>电话：17311478139</div>
      </div>
    </div>
    <div class="box_title">
      <div class="home_title fs-40">提交订单</div>
    </div>
    <field-piker
      label="邮寄地址"
      title="请选择邮寄地址"
      :columns="mailAddressList"
      placeholder="请选择邮寄地址"
      v-model="formData.address"
    ></field-piker>
    <select-search-cell v-if="this.couponType != '1'" title="活动机型" placeholder="请输入查询或选择活动机型" v-model="formData.old_model"
      :options="couponPhoneModelList" id-key="model_name" label-key="model_name" 
      @change="($event) => {
        setSelectValue($event, 'old_model');
      }
        "></select-search-cell>
    <field-piker
      v-if="this.couponType != '1'"
      label="活动机型内存"
      title="请选择活动机型内存"
      :columns="couponPhoneRamList"
      placeholder="请选择活动机型内存"
      v-model="formData.old_ram"
      @clickDiaShow="selectRepairer"
    ></field-piker>
    <field-piker
      v-if="this.couponType != '1'"
      label="订单类型"
      title="请选择订单类型"
      :columns="reasonList"
      placeholder="请选择订单类型"
      v-model="orderMoldVal"
      @clickDiaShow="selectRepairer4"
      ></field-piker>
    <select-search-cell v-if="this.formData.order_mold == '2'" title="新机品牌" placeholder="请输入查询或选择新机品牌" v-model="formData.new_brand"
      :options="phoneBrandList" id-key="brand" label-key="brand" 
      @change="($event) => {
        setSelectValue($event, 'new_brand');
      }
        "></select-search-cell>
    <select-search-cell v-if="this.formData.order_mold == '2'" title="新机机型" placeholder="请输入查询或选择新机机型" v-model="formData.new_model"
      :options="modelListOption" id-key="model_name" label-key="model_name" @click.native="selectRepairer2"
      @change="($event) => {
        setSelectValue($event, 'new_model');
      }
        "></select-search-cell>
    <field-piker
      v-if="this.formData.order_mold == '2'"
      label="新机内存"
      title="请选择新机内存"
      :columns="phoneRamList"
      placeholder="请选择新机内存"
      v-model="formData.new_ram"
      @clickDiaShow="selectRepairer3"
    ></field-piker>
    <van-field
      v-if="this.formData.order_mold == '2'"
      v-model="formData.new_colour"
      input-align="right"
      label="新机颜色"
      placeholder="请输入新机颜色"
    />
    <van-field
      v-if="(this.formData.order_mold || couponType=='1') && this.formData.order_mold != '3'"
      v-model="formData.user_address"
      input-align="right"
      label="回寄地址"
      placeholder="请输入回寄地址"
    />
    <van-field
      v-if="this.formData.order_mold == '3'"
      v-model="formData.user_account"
      input-align="right"
      label="支付宝账号"
      placeholder="请输入支付宝账号"
    />
    <van-cell
      title="活动机价格"
      v-if="this.formData.order_mold"
      :value="formData.old_price"
    />
    <van-cell
      title="新机价格"
      v-if="this.formData.order_mold == '2'"
      :value="formData.new_price"
    />
    <van-cell
      title="抵扣金额"
      v-if="this.formData.order_mold"
      :value="formData.claims_money"
    />
    <van-cell
      title="补缴金额"
      v-if="this.formData.order_mold == '2'"
      :value="formData.money"
    />
    <div class="submit-btn">
      <my-button @click="submit">提交</my-button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  mailAddress,
  couponOrder,
  orderMold,
  couponPhoneModel,
  couponPhoneRam,
  couponPrice,
} from "@/api/couponOrder";
import { getPhoneBrand, getPhoneModel, getPhoneRam } from "@/api";
export default {
  name: "orderhome",
  data() {
    return {
      couponType: "",
      formData: {
        coupon_id: "",
        order_mold: "",
        address: "",
        user_address: "",
        user_account: "",
        old_model: "",
        old_ram: "",
        old_price: "",
        claims_money: "",
        new_brand: "",
        new_model: "",
        new_ram: "",
        new_colour: "",
        money: "",
      },
      orderMoldVal: "",
      value: "",
      reasonList: [],
      mailAddressList: [],
      couponPhoneModelList: [],
      couponPhoneRamList: [],
      phoneBrandList: [],
      modelListOption: [],
      phoneRamList: [],
      modelList: [],
    };
  },
  created() {
    this.formData.coupon_id = this.$route.query.couponId;
    this.couponType = this.$route.query.type;
    this.getMailAddress();
    this.getCouponPhoneModel();
    this.getPhoneBrandList();
  },
  watch: {
    orderMoldVal(val) {
      this.formData.order_mold = val == "以旧换新" ? "2" : val == "纯回收"?"3":"";
      this.getCouponPrice()
    },
    // "formData.old_model": {
    //   handler() {
    //     this.formData.old_ram = "";
    //     this.getCouponPhoneRam();
    //     this.getCouponPrice();
    //     this.getOrderMold();
    //   },
    // },
    "formData.old_ram": {
      handler(val) {
        console.log(val,'+++++++++++++');
        if(val){
          this.getCouponPrice();
          this.getOrderMold();
        }else{
          this.orderMoldVal = ""
        }
        
      },
    },
    "formData.new_ram": {
      handler() {
        this.getCouponPrice();
      },
    },
  },
  mounted() {},
  methods: {
    getMailAddress() {
      mailAddress().then((res) => {
        this.mailAddressList = res.data.map((item) => item.name);
      });
    },
    getOrderMold() {
      orderMold({old_model: this.formData.old_model,old_ram: this.formData.old_ram,}).then((res) => {
        this.reasonList = res.data.map((item) => item.name);
      });
    },
    getCouponPhoneModel() {
      couponPhoneModel({ coupon_id: this.formData.coupon_id }).then((res) => {
        this.couponPhoneModelList = res.data;
      });
    },
    getCouponPhoneRam() {
      couponPhoneRam({ old_model: this.formData.old_model }).then((res) => {
        this.couponPhoneRamList = res.data.map((item) => item.ram_size);
      });
    },
    getPhoneBrandList() {
      getPhoneBrand().then((res) => {
        this.phoneBrandList = res.data.brandList;
      });
    },
    getModelList() {
      const foundItem = this.phoneBrandList.find(
        (item) => item.brand === this.formData.new_brand
      );
      let brand_id = "";
      if (foundItem) {
        brand_id = foundItem.brand_id;
      }
      getPhoneModel({ brand_id }).then((res) => {
        this.modelListOption = res.data;
        this.modelList = res.data;
      });
    },
    getPhoneRamList() {
      const foundItem = this.modelList.find(
        (item) => item.model_name === this.formData.new_model
      );
      let model_id = "";
      if (foundItem) {
        model_id = foundItem.model_id;
      }
      getPhoneRam({ model_id }).then((res) => {
        this.phoneRamList = res.data.map((item) => item.ram_size);
      });
    },
    getCouponPrice() {
      let parame = {
        coupon_id: this.formData.coupon_id,
        order_mold: this.formData.order_mold,
        old_model: this.formData.old_model,
        old_ram: this.formData.old_ram,
        new_brand: this.formData.new_brand,
        new_model: this.formData.new_model,
        new_ram: this.formData.new_ram,
      };
      couponPrice(parame).then((res) => {
        this.formData.old_price = res.data.old_price;
        this.formData.claims_money = res.data.claims_money;
        this.formData.money = res.data.money;
        this.formData.new_price = res.data.new_price;
      });
    },
    submit() {
      if (this.couponType != "1" && !this.orderMoldVal) {
        Toast("请选择订单类型");
        return;
      }
      if (!this.formData.address) {
        Toast("请选择邮寄地址");
        return;
      }
      couponOrder(this.formData).then((res) => {
        this.$myDialog({
          type: "",
          title: "后续请查询优惠劵，查看订单进度",
          confirmButtonText: "查看详情",
          beforeClose: (action, done) => {
            if (action === "confirm") {
              done();
              this.$router.push({
                path: "/CouponOrder/couponOrderInfo",
                query: {
                  orderId: res.data.order_id,
                },
              });
            } else {
              done();
            }
          },
        });
      });
    },
    selectRepairer() {
      if (!this.formData.old_model) {
        Toast("请先选择活动机型");
      }
    },
    selectRepairer2() {
      if (!this.formData.new_brand) {
        Toast("请先选择新机品牌");
      }
    },
    selectRepairer3() {
      if (!this.formData.new_model) {
        Toast("请先选择新机机型");
      }
    },
    selectRepairer4() {
      if (!this.formData.old_model || !this.formData.old_ram) {
        Toast("请先选择活动机型和活动机型内存");
      }
    },
    //确认选择
    setSelectValue(value, name) {
      console.log('setSelectValue确认选择', value, name)
      switch (name) {
        // 手机型号
        case "old_model":
          this.formData.old_ram = "";
          this.formData.old_model = value.model_name;
          this.getCouponPhoneRam();
          this.getCouponPrice();
          break;
        case "new_brand":
          this.formData.new_model = "";
          this.formData.new_ram = "";
          this.getModelList();
          this.getCouponPrice();
          break;
        case "new_model":
          this.formData.new_ram = "";
          this.getPhoneRamList();
          this.getCouponPrice();
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.order_home {
  position: relative;
  width: 100vw;
  .box_title {
    padding: 40px 30px;

    font-family: PingFang SC;
    font-weight: 800;
    color: #333333;
  }
  .submit-btn {
    width: 100vw;
    padding: 50px 20px;
  }
  .bx_item {
    width: calc(100vw - 60px);
    margin: 20px auto;

    .box {
      line-height: 44px;
      margin-top: 20px;
      padding: 28px 25px;
      background: #f6f8fe;
      border-radius: 16px;
      color: #333333;
      font-family: PingFang SC;
      font-weight: 400;
    }
  }
}
</style>
